<template>
  <div class="EditPassword">
    <div class="container">
      <h1>找回密码</h1>
      <el-form :model="findPswForm" :rules="rules" ref="editPswForm" label-width="100px" class="demo-ruleForm" style="width: 450px;margin:0px auto">
        <el-form-item>
        </el-form-item>
        <el-form-item label="账户名称" prop="username">
          <el-input v-model="findPswForm.username" disabled placeholder="请输入账户名称"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="phoneNum">
          <el-input v-model="findPswForm.phoneNumber" disabled placeholder="请输入与账户绑定的手机号"></el-input>
        </el-form-item>
        <el-form-item label="短信验证码" prop="phoneCode">
          <el-input v-model="findPswForm.phoneCode" autocomplete="off" placeholder="请输入短信验证码">
            <el-button @click="getLoginPhoneCode" :disabled="!showGetCode" type="text"
                       style="padding-right:10px;color:#0366C3;" slot="suffix">
              <span v-if="showGetCode">获取验证码</span>
              <span v-else class="count">{{codeTime}} s</span>
            </el-button>
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPass">
          <el-input v-model="findPswForm.newPass" placeholder="请输入新密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="newPass_repeat">
          <el-input v-model="findPswForm.newPass_repeat" placeholder="再次输入新密码" show-password></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top:100px">
        <el-button type="primary" @click="subChangePass">立即找回</el-button>
        <el-button plain @click="$oucy.back()">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import oucy from "../../../util/oucyUtil";
export default {
  name: 'FindPassword',
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!oucy.isValidPhone(value)) {
        callback(new Error('请输入正确的11位手机号码'))
      } else {
        callback()
      }
    };
    return {
      showGetCode: true,
      count: '',
      findPswForm: {
        newPass: '',
        newPass_repeat: '',
        phoneCode: '',
        phoneNumber: '',
        username: ''
      },
      rules: {
        phoneNumber: [
          {validator: validatePhone, trigger: 'blur'}
        ],
        phoneCode: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
        ],
        newPass: [
          {required: true, message: '请输入新密码', trigger: 'blur'},
        ],
        newPass_repeat: [
          {required: true, message: '再次输入新密码', trigger: 'blur'},
        ]
      }
    }
  },
  mounted() {
    let userInfoEntity = oucy.getLocalUserEntity();
    if(userInfoEntity){
      console.log(userInfoEntity)
      this.findPswForm.username=userInfoEntity.userDcodeUsername
      this.findPswForm.phoneNumber=userInfoEntity.userPhoneNumber
    }

  },
  methods: {
    resetForm: function(formName) {
      this.$refs[formName].resetFields();
    },
    subChangePass: function () {
      const me = this;
      me.$refs["editPswForm"].validate((valid) => {
        if (valid) {

          oucy.postRequest('/client/user/userauth/changePassByCode', {
            newPass_repeat: me.findPswForm.newPass_repeat,
            newPass: me.findPswForm.newPass,
            phoneCode: me.findPswForm.phoneCode,
            phoneNumber: me.findPswForm.phoneNumber,
          }).then(res => {
            me.$notify({
              title: '修改成功',
              message: '密码已经修改，你需要重新登录',
              type: 'success',
              onClose: function () {
              }
            });
            setTimeout(()=>{
              oucy.logout();
              oucy.replace('/');
              location.reload();
            },2000)
          })

        }
      });
    },
    getLoginPhoneCode: function () {
        const me = this;
        me.$refs['editPswForm'].validateField('phoneNum', (message) => {
            if (message == null || message.length === 0) {
                oucy.postRequest('/client/user/userauth/changePassSendCode', {
                    phoneNumber: me.findPswForm.phoneNumber,
                    uuid: oucy.getUUID()
                }).then(res => {
                    me.codeTime = Number(res);
                    me.$notify({
                        title: '短信发送成功',
                        message: '验证码已发送，请留意短信通知',
                        type: 'success'
                    });
                });
            }
        });
    },

  }
}
</script>

<style scoped>
.container{
  text-align: left;
  width: 100%;
  height:800px;
  background: #FFFFFF;
  /*margin-top:-28px;*/
}
h1{
  font-size: 28px;
  font-weight: bold;
  color: #555C68;
  text-align: center;
  padding: 100px 0 30px 0;
}
/deep/ .el-form-item label:after {
  content: " ";
}

/deep/ .el-form-item__label {
  text-align-last: justify
}
/* 这里去除必选字段的*,这个符号会造成一定影响 */
/deep/ .el-form-item.is-required .el-form-item__label:before {
  content: none !important;
}
</style>
